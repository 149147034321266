import React from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
// import SignUpForm from './style'
import Content from './style'
import SectionTitle from './Components/SectionTitle'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Images } from '~data'
import { Link } from '~components'
// import SectionTitle from './Components/SectionTitle'
import { SuperTag } from '~components';
import { border } from 'polished'

import { style } from '@mui/system'
// import ReactTypingEffect from 'react-typing-effect';


export default function Started() {
    return (
        <Content>
            <Container
            //  className="position-static"
            >

                <Content.Inner>
                    <Row className="row justify-content-center p-5 mt-5">
                        <Col className="col-lg-9 col-xl-9 col-xxl-7 text-center">
                            <SectionTitle

                                title="Select all supported platforms."

                                subTitleProps={{ mb: "27px" }}
                                titleProps={{ mb: "10px", as: "h2" }}
                                mb="20px" />
                        </Col>
                    </Row>





                    <Row className="align-items-center justify-content-center position-static p-6">

                        <Col xs="12" className="  col-lg-3 col-md-4  ">

                            <Card
                                style={{ minHeight: '170px', height: 'auto' }} className="rounded-4 shadow text-center" >
                                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                                <Card.Body>
                                    <Card.Title style={{ color: '#8a55df', 'fontSize': '25px', align: 'center' }}>  <div> <i className="fa fa-globe" style={{ backgroundColor: '#ffffff', color: '#8a55df', 'fontSize': '40px', padding: '4px' }} ></i> </div> <br /> Web</Card.Title>
                                    <Card.Text style={{ color: '#000000', 'fontSize': '16px' }}>
                                    </Card.Text>


                                </Card.Body>
                            </Card>
                        </Col>


                        <Col xs="12" className="  col-lg-3 col-md-4  ">

                            <Card
                                style={{ minHeight: '170px', height: 'auto' }} className="rounded-4 shadow text-center" >
                                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                                <Card.Body>
                                    <Card.Title style={{ color: '#8a55df', 'fontSize': '25px', align: 'center' }}>  <div> <i className="fa fa-apple" style={{ backgroundColor: '#ffffff', color: '#8a55df', 'fontSize': '40px', padding: '4px' }} ></i> </div> <br /> Web</Card.Title>
                                    <Card.Text style={{ color: '#000000', 'fontSize': '16px' }}>
                                    </Card.Text>


                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs="12" className="  col-lg-3 col-md-4  ">

                            <Card
                                style={{ minHeight: '170px', height: 'auto' }} className="rounded-4 shadow text-center" >
                                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                                <Card.Body>
                                    <Card.Title style={{ color: '#8a55df', 'fontSize': '25px', align: 'center' }}>  <div> <i className="fa fa-android" style={{ backgroundColor: '#ffffff', color: '#8a55df', 'fontSize': '40px', padding: '4px' }} ></i> </div> <br /> Web</Card.Title>
                                    <Card.Text style={{ color: '#000000', 'fontSize': '16px' }}>
                                    </Card.Text>


                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs="12" className="  col-lg-3 col-md-4  ">

                            <Card
                                style={{ minHeight: '170px', height: 'auto' }} className="rounded-4 shadow text-center" >
                                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                                <Card.Body>
                                    <Card.Title style={{ color: '#8a55df', 'fontSize': '25px', align: 'center' }}>  <div> <i className="fa fa-windows" style={{ backgroundColor: '#ffffff', color: '#8a55df', 'fontSize': '40px', padding: '4px' }} ></i> </div> <br /> Web</Card.Title>
                                    <Card.Text style={{ color: '#000000', 'fontSize': '16px' }}>
                                    </Card.Text>


                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Content.Inner>
            </Container>

        </Content >

    )
}
