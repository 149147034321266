import React from "react";
import { PageWrapper } from "~components/Core";
// import HeroSection from '~sections/project/Hero'
import Proccess from '../sections/Proccess/Proccess'





export default function GetStarted() {
    return (
        <PageWrapper innerPage={true}>



            <Proccess />

        </PageWrapper>
    )
}
